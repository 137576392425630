<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">单据详情</span>
      <div class="row">
        <div class="col-md-12">
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="1">
            <el-table-column prop="STEP" label="步骤" width="60" align="center" />
            <el-table-column prop="DESCRIPTION" label="摘要" width="100" />
            <el-table-column prop="PTEA_NAME" label="处理人" width="100" align="center" />
            <el-table-column prop="STEP_NAME" label="步骤" width="80" align="center"  />
            <el-table-column prop="STA_TXT" label="状态" width="80" align="center"  />
            <el-table-column label="日期" width="100">
              <template slot-scope="scope" align="center">{{scope.row.CREATED_DT | datetime('YYYY-MM-DD')}}</template>
            </el-table-column>
            <el-table-column />
          </wgrid>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Wgrid from "@/components/wgrid";
export default {
  components: {Wgrid},
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      grid: {
        total: 0,
        ls: [],
        loading: false
      },
    }
  },
  methods:{
    init(tp) {
      this.sta={show:true,loading:false}
      this.getList(tp.ID);
    },
    getList(id){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getResult({
        url: "/api/School/WFL/ActProApi/GetList",
        data: {SID:id},
        completed: function (its) {
          self.grid.ls=its.DATA;
          self.grid.total=its.DATA.length;
          self.grid.loading=false;
        }
      })
    }
  }
}
</script>
